jQuery(function ($) {
  $(document).ready(function () {
    $("#findMoreVideo a").on("click", function (event) {
      // Make sure this.hash has a value before overriding default behavior
      if (this.hash !== "") {
        // Prevent default anchor click behavior
        event.preventDefault();

        // Store hash
        var hash = this.hash;

        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
        $("html, body").animate(
          {
            scrollTop: $(hash).offset().top - 100,
          },
          300
        );
      } // End if
    });

    // Carousel
    if ($(".carousel-techincal-video").length > 0) {
      let initialFilter = "carousel-0";
      let initialTitle = $(
        ".carousel-technical-video-filter a:first-child"
      ).data("title");

      $(".carousel-techincal-video")
        .slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          infinite: true,
          autoplaySpeed: 3000,
          autoplay: true,
        })
        .slick("slickFilter", "." + initialFilter);

      $("#carouselTitle").append(initialTitle);
      $('[data-filter="' + initialFilter + '"]').addClass("filter-active");

      $(".carousel-technical-video-filter a").on("click", function (e) {
        e.preventDefault();
        let filter = $(this).data("filter");
        let title = $(this).data("title");

        $(".carousel-techincal-video")
          .slick("slickUnfilter")
          .slick("slickFilter", "." + filter);

        $("#carouselTitle").html(title);
        $(".carousel-technical-video-filter a.filter-active").removeClass(
          "filter-active"
        );
        $(this).addClass("filter-active");
      });
    }
  });
});
