document.addEventListener("DOMContentLoaded", (event) => {
  if (document.querySelectorAll(".img-layer-2").length > 0) {
    // ScrollTrigger
    gsap.registerPlugin("ScrollTrigger");

    interactiveAnimation();

    window.addEventListener("load", () => {
      ScrollTrigger.refresh();
    });

    window.addEventListener("resize", () => {
      if (window.innerWidth >= 992) {
        window.scrollTo({
          top: 0, // La posizione in pixel (0 per andare alla parte superiore)
          behavior: "smooth", // Aggiunge l'effetto di scorrimento fluido
        });
        setTimeout(() => {
          ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
          interactiveAnimation();

          setTimeout(() => {
            ScrollTrigger.batch(".fade-up", {
              start: "top 80%",
              onEnter: (elements, triggers) => {
                gsap.to(elements, {
                  opacity: 1,
                  stagger: 0.3,
                  y: 0,
                  duration: 1.5,
                });
              },
            });

            ScrollTrigger.batch(".fade-left", {
              start: "top 80%",
              onEnter: (elements, triggers) => {
                gsap.to(elements, {
                  opacity: 1,
                  stagger: 0.3,
                  x: 0,
                  duration: 1.5,
                });
              },
            });
          }, 1000);
        }, 1500);
      }
    });

    if (document.getElementById("interactiveManualTitle")) {
      position_title();
      window.addEventListener("resize", position_title);

      gsap.to("#interactiveManualTitle", {
        opacity: 0.61,
        stagger: 0.3,
        y: 0,
        duration: 1.5,
      });
    }
  }
});

function position_title() {
  if (window.innerWidth >= 992) {
    const container = document
      .getElementById("layerContainer")
      .getBoundingClientRect();
    const title = document.getElementById("interactiveManualTitle");

    //   title.style.top = `${container.top - 70}px`;
    title.style.left = `${container.left + 40}px`;
  }
}

function interactiveAnimation() {
  if (window.innerWidth >= 992) {
    const layers = [
      {
        element: ".img-layer-2",
        container: ".layer-text-2",
      },
      {
        element: ".layer-3-container",
        container: ".layer-text-3",
      },
    ];

    layers.forEach((item) => {
      gsap.to(item.element, {
        autoAlpha: 1,
        duration: 0.3,
        ease: "linear",
        scrollTrigger: {
          trigger: item.container,
          //   markers: true,
          start: "-40px center",
          end: "bottom center", // Specifica un punto di fine per stabilità
          toggleActions: "play none none reverse",
          invalidateOnRefresh: true, // Ricalcola dopo il refresh
          onEnter: () => {
            if (item.element === ".layer-3-container") {
              const vid = document.getElementById("videoLayer3");
              vid.play();
            }
          },
          onLeave: () => {
            if (item.element === ".layer-3-container") {
              const vid = document.getElementById("videoLayer3");
              vid.pause();
            }
          },

          onLeaveBack: () => {
            if (item.element === ".layer-3-container") {
              const vid = document.getElementById("videoLayer3");
              vid.pause();
            }
          },
        },
      });
    });
  }
}
