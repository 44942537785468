// GSAP Animation for the accordion
document.addEventListener("DOMContentLoaded", function () {
  if (document.querySelectorAll(".ccms-accordion").length > 0) {
    const accordionItems = document.querySelectorAll(".ccms-accordion li");
    const images = document.querySelectorAll(".accordion-image");

    // Function to switch active accordion, content, and image
    function switchAccordion(index) {
      accordionItems.forEach((item, i) => {
        const content = item.querySelector(".accordion-content");

        if (i === index) {
          // Attiva l'elemento cliccato
          item.classList.add("active");
          gsap.to(content, {
            maxHeight: 200,
            opacity: 1,
            visibility: "visible",
            duration: 0.5,
          });
        } else {
          // Disattiva gli altri elementi
          item.classList.remove("active");
          gsap.to(content, {
            maxHeight: 0,
            opacity: 0,
            visibility: "hidden",
            duration: 0.5,
          });
        }
      });

      // Cambia immagine
      images.forEach((img, i) => {
        if (i === index) {
          gsap.to(img, { opacity: 1, visibility: "visible", duration: 0.5 });
        } else {
          gsap.to(img, { opacity: 0, visibility: "hidden", duration: 0.5 });
        }
      });
    }

    // Initialize first state
    switchAccordion(0);

    // Add event listeners to accordion items
    accordionItems.forEach((item, index) => {
      item
        .querySelector(".accordion-title")
        .addEventListener("click", () => switchAccordion(index));
    });

    // if (document.querySelectorAll(".ccms-section").length > 0) {
    //   gsap.registerPlugin(ScrollTrigger);

    //   let tl = gsap.timeline({
    //     scrollTrigger: {
    //       trigger: ".ccms-section-container",
    //       pin: true,
    //       scrub: true,
    //       end: "+=" + document.querySelectorAll(".ccms-section").length + "00%",
    //       // markers: true,
    //     },
    //   });

    //   let panels = gsap.utils.toArray(".ccms-section");

    //   panels.forEach((panel, i) => {
    //     if (!i) {
    //       tl.set({}, {}, 0.5);
    //     } else {
    //       tl.to(
    //         panel,
    //         {
    //           yPercent: -100,
    //           ease: "none",
    //         },
    //         "+=1"
    //       );
    //     }
    //   });

    //   window.addEventListener("load", () => {
    //     ScrollTrigger.refresh();
    //   });

    //   window.addEventListener("resize", () => {
    //     ScrollTrigger.refresh();
    //   });
    // }

    if (document.querySelectorAll(".ccms-section").length > 0) {
      let sections = gsap.utils.toArray(".ccms-section"),
        currentSection = sections[0];

      gsap.defaults({ overwrite: "auto", duration: 1.3 });

      // Animazione per il primo elemento al caricamento della pagina
      gsap.to(currentSection, { autoAlpha: 1, duration: 1.3, zIndex: 4 });

      // stretch out the body height according to however many sections there are.
      //gsap.set("body", {height: (sections.length * 100) + "%"});

      // create a ScrollTrigger for each section
      sections.forEach((section, i) => {
        ScrollTrigger.create({
          // use dynamic scroll positions based on the window height (offset by half to make it feel natural)
          trigger: ".ccms-section-container",
          pin: true,
          start: (self) => (i === 0 ? "top top" : self.previous().end),
          end: () => "+=" + innerHeight,
          scrub: true,
          // markers: true,
          toggleActions: "restart none reverse reset",
          // when a new section activates (from either direction), set the section accordingly.
          onToggle: (self) => self.isActive && setSection(section),
        });
      });

      function setSection(newSection) {
        if (newSection !== currentSection) {
          gsap.timeline().to(currentSection, { autoAlpha: 0, duration: 0.5, zIndex: 1 });

          gsap.timeline().to(newSection, { autoAlpha: 1, duration: 0.5, zIndex: 4 });
          currentSection = newSection;
        }
      }
    }
  }
});

jQuery(function ($) {
  $(document).ready(function () {
    $(".ccms-advantages-carousel").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      infinite: true,
      autoplaySpeed: 3000,
      autoplay: false,
      dots: true,
    });
  });
});
