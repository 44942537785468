jQuery(function ($) {
  const gallery = $(".render-gallery").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: ".render-gallery-nav",
  });

  $(".render-gallery-nav").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".render-gallery",
    dots: false,
    focusOnSelect: true,
    draggable: false,
  });



  // Navigazione tramite hover
  $(".render-gallery-nav .render-gallery-button img").on("mouseover", function () {
    const index = $(this).closest('.render-gallery-button').index();
    $(".render-gallery").slick("slickGoTo", index);

    // Blocca temporaneamente lo scrolling del navigatore
    $(".render-gallery-nav").slick("slickSetOption", "swipe", false, true);
  });

  // Riabilita lo scrolling al termine dell'hover
  $(".render-gallery-nav .render-gallery-button").on("mouseout", function () {
    $(".render-gallery-nav").slick("slickSetOption", "swipe", true, true);
  });
});
