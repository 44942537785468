document.addEventListener("DOMContentLoaded", (event) => {

  if (document.querySelector(".sic-webmanual-hero")) {
    gsap.registerPlugin("ScrollTrigger");

    const imgTl = gsap.timeline();

    imgTl.to(
      ".item-anime-2",
      {
        top: 0,
        ease: "ease",
        duration: 3,
      },
      "-=5"
    );

    imgTl.to(
      ".webmanual-hero-title .first-title",
      {
        y: -100,
        ease: "ease",
        autoAlpha: 0,
        duration: 1.5,
      },
      "-=5"
    );

    imgTl.to(
      ".webmanual-hero-title .second-title",
      {
        y: -90,
        ease: "ease",
        autoAlpha: 1,
        duration: 1.5,
      },
      "-=5"
    );

    imgTl.to(
      ".webmanual-hero-title .btn-hero",
      {
        ease: "ease",
        y: -20,
        autoAlpha: 1,
        duration: 1,
      },
      "-=3"
    );

    imgTl.to("header .navbar", {
      background: "#FFF",
      duration: 1,
    });

    ScrollTrigger.create({
      animation: imgTl,
      trigger: ".sic-webmanual-hero",
      start: "center center", // Inizia quando il top della sezione raggiunge il centro del viewport
      end: "+=500", // Termina quando il bottom della sezione lascia il centro
      pin: true, // Pin la sezione durante l'animazione
      // markers: true, // Rimuovi se non vuoi i marker
      scrub: 1,
    });
  }
  
});
