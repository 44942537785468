document.addEventListener("DOMContentLoaded", function () {
  const overlay = document.querySelector(".sic-overlay");
  const modal = document.getElementById("portfolioModal");
  const modalTitle = document.getElementById("sicModalTitle");
  const modalSubTitle = document.getElementById("sicModalSubtitle");
  const modalText = document.getElementById("sicModalText");
  const modalContent = document.querySelector(".sic-modal-content");
  const modalVideo = document.getElementById("sicModalVideo");
  const modalCategory = document.getElementById("sicModalCategory");

  window.showSicModal = function (
    button,
    title = "",
    year = "",
    text = "",
    video = "",
    category = ""
  ) {
    const buttonRect = button.getBoundingClientRect();

    // Posizione iniziale della modale sopra il pulsante
    modal.style.top = `${buttonRect.top}px`;
    modal.style.left = `${buttonRect.left}px`;
    // modal.style.transform = "translate(-50%, -50%) scale(0)";
    modal.style.height = `${buttonRect.height - 20}px`;
    modal.style.width = `${buttonRect.width - 20}px`;
    modal.style.display = "block";
    modal.style.transform = "scale(0.1)";

    // Aggiorna il contenuto della modale
    modalTitle.innerText = title;
    modalSubTitle.innerText = year;
    modalCategory.innerText = category;

    if (document.getElementById(text)) {
      modalText.innerHTML = document.getElementById(text).innerHTML;
    }

    if (document.getElementById(category)) {
      modalCategory.innerHTML = `<span class="mb-2 portfolio-tax-item bg-primary">${
        document.getElementById(category).innerText
      }</span> <a class="mb-2 modal-info" href="/prezzi/">INFO <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
</a>`;
    }

    if (video !== "") {
      const videoElement = `
                    <div style="border-radius:19px; position: relative; padding-bottom: 56.25%; max-width: 742px; height: 565px; overflow: hidden; width: 100%; background: #000;">
                        <video src="${video}" 
                               style="border-radius:19px; position: absolute; top: 0; left: 0; width: 100%; height: 100%; max-width: 742px; max-height: 565px;" 
                               controls="" controlslist="nodownload" class="w-100 h-100 mb-md-0 mb-5" autoplay="0" loop="0">
                            Il tuo browser non supporta il tag video.
                        </video>
                    </div>
                `;
      // Inietta il codice del video nel div target
      modalVideo.innerHTML = videoElement;
    }

    // Mostra overlay e modale
    overlay.classList.add("sic-active");
    modal.classList.add("sic-active");
    document.body.classList.add("sic-modal-overflow-hidden");

    // Imposta altezza massima in base alle dimensioni della finestra
    const maxModalHeight = Math.min(565, window.innerHeight - 100); // 100px di margine
    modalContent.style.maxHeight = `${maxModalHeight}px`;
    modalContent.style.overflowY = "hidden";

    setTimeout(() => {
      modal.style.transform = "translate(-50%, -50%) scale(1)";
      modal.style.top = "50%";
      modal.style.left = "50%";
      setTimeout(() => {
        // Nuova animazione
        modal.style.width = "100%";
        modal.style.minHeight = `${
          parseInt(modalContent.style.maxHeight) + 96
        }px`;

        setTimeout(() => {
          // Nuova animazione
          modalContent.style.overflowY = "";
        }, 500); // Tempo di attesa per la nuova animazione
      }, 300); // Tempo di attesa per la nuova animazione
    }, 80);
  };

  window.hideSicModal = function () {
    modal.style.transform = "translate(-50%, -50%) scale(0)";
    setTimeout(() => {
      overlay.classList.remove("sic-active");
      modal.classList.remove("sic-active");

      setTimeout(() => {
        // Nuova animazione
        modal.style = "";
        document.body.classList.remove("sic-modal-overflow-hidden");
      }, 80); // Tempo di attesa per la nuova animazione
    }, 80);
    modalVideo.innerHTML = "";
    modalText.innerHTML = "";
    modalTitle.innerText = "";
    modalSubTitle.innerText = "";
    modalCategory.innerCategory = "";
  };

  if (document.getElementById("portfolioTaxNavigation")) {
    window.addEventListener("scroll", function () {
      const fixedElement = document.getElementById("portfolioTaxNavigation");
      const scrollPosition = window.innerHeight + window.scrollY; // Posizione attuale dello scroll
      const documentHeight = document.documentElement.offsetHeight; // Altezza totale del documento

      if (scrollPosition >= documentHeight) {
        fixedElement.classList.add("portfolioTaxNavigationHidden"); // Applica la classe per nascondere
      } else {
        fixedElement.classList.remove("portfolioTaxNavigationHidden"); // Rimuovi la classe per mostrare
      }
    });
  }
});

// Ajax call portfolio
jQuery(document).ready(function ($) {
  $(".portfolio-navigation-item").on("click", function () {
    // Aggiunge la classe attiva all'elemento cliccato
    $(".portfolio-navigation-item").removeClass(
      "portfolio-navigation-item-active"
    ); // Rimuove la classe da tutti gli elementi
    $(this).addClass("portfolio-navigation-item-active"); // Aggiunge la classe all'elemento cliccato
    console.log($(this).data("id"));
    get_portfolio_item($(this).data("id"));
  });

  $("#portfolioTaxSelect").on("change", function () {
    console.log($(this).val());
    if (parseInt($(this).val()) !== -1) {
      get_portfolio_item($(this).val());
    }
  });

  function get_portfolio_item(id) {
    
    const dataId = parseInt(id);

    const container = $("#portfolioResults");
    const loader = $(`
        <div id="loader" style="display: flex; justify-content: center; align-items: center; margin-top: 20px; height:400px;">
            <div class="spinner"></div>
        </div>
    `);

    $.ajax({
      url: wlow_php_vars.ajaxUrl,
      type: "POST",
      data: {
        action: "portfolio_tax_navigation", // Nome dell'azione registrata in PHP
        data_id: dataId,
        security: wlow_php_vars.nonce, // Nonce di sicurezza
      },
      beforeSend: function () {
        container.empty(); // Svuota il contenitore dei risultati precedenti
        container.append(loader); // Mostra il loader
      },
      success: function (response) {
        // Imposta un ritardo minimo di 1 secondo prima di mostrare i risultati
        setTimeout(() => {
          container.empty(); // Rimuove il loader
          if (response) {
            container.append(response); // Mostra i nuovi risultati
            gsap.to(".fade-up", {
              opacity: 1,
              stagger: 0.3,
              y: 0,
              duration: 1.5,
            });
          } else {
            container.append("<div>Errore: nessun dato restituito.</div>");
          }
        }, 500); // Ritardo di 1 secondo
      },
      error: function (xhr, status, error) {
        console.error("Errore AJAX: ", error);
      },
    });
  }
});
